import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { window } from "browser-monads"
import { Container, Nav, Navbar, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import "font-awesome/css/font-awesome.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import "./navigationbar.css"
import ToggleLanguage from "./ToggleLanguage"
import logoPutih from "../../images/logo-transfez-white-new.svg"
import logoWarna from "../../images/logo-transfez-new.svg"
import {
  StyledLink,
  ResLink,
  ResLinkHref,
  StyledSpan,
  StyledLinkHref,
} from "./HeaderStyle"
import { ToggleBusinessDiv, BusinessDivHide } from "./NavigationStyle"
import { TestingTooltip } from "./TestingTooltip"
import { RegionalTooltip } from "./RegionalTooltip"
import idFlag from "../../images/small-id-flag.svg"
import sgFlag from "../../images/small-sg-flag.svg"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { ToggleSignInBusiness, ToggleBusiness } from "./NavbarComponents"

const NavbarLink = ({ data }) => (
  <>
    {data.map((detail, id) => {
      const { to, title, color } = detail
      return (
        <Nav.Item as="li" key={id}>
          <StyledLink to={to} color={color}>
            {title}
          </StyledLink>
        </Nav.Item>
      )
    })}
  </>
)

const NavbarLinkHref = ({ data }) => (
  <>
    {data.map((detail, id) => {
      const { to, title, color } = detail
      return (
        <Nav.Item as="li" key={id}>
          <StyledLinkHref href={to} color={color} target="_blank">
            {title}
          </StyledLinkHref>
        </Nav.Item>
      )
    })}
  </>
)

const NavigationBar = ({
  props,
  propsLink,
  parentCallback,
  disableNav,
  noToggleBusiness,
  isWhite,
  isBusiness,
  noRegional,
}) => {
  const [modalNavbar, setModalNavbar] = useState(false)
  const [navbarTop, setNavbarTop] = useState(false)
  config.autoAddCss = false
  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarTop(true)
      parentCallback(true)
    } else {
      setNavbarTop(false)
      parentCallback(false)
    }
  }

  window.addEventListener("scroll", changeBackground)

  const navbarItems = [
    [
      {
        to: propsLink.HowItWorks,
        title: props.NavbarLi.Nav1,
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
    [
      {
        to: propsLink.Countries,
        title: props.NavbarLi.Nav2,
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
    [
      {
        to: propsLink.Business,
        title: props.NavbarLi.Nav3,
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
    [
      {
        to: propsLink.ContactUs,
        title: props.NavbarLi.Nav4,
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
    [
      {
        to: "https://wa.me/628111390993",
        title: props.NavbarLi.Nav5,
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
    [
      {
        to: "/",
        title: "Regional",
        color: navbarTop ? "white" : "#49a9b5",
      },
    ],
  ]

  const location = useLocation()
  const lang = location.pathname.includes("/en")
  const isSg = location.pathname.includes("/sg")

  return (
    <div
      className={navbarTop ? "navbarTop" : "navbarTopActive"}
      style={isWhite && !navbarTop ? { backgroundColor: "white" } : null}
    >
      <Container>
        {modalNavbar ? (
          <div className="menu-mobile">
            <div className="menu-mobile-wrapper px-4 pt-5">
              <FontAwesomeIcon
                icon={faTimes}
                size="2x"
                cursor="pointer"
                onClick={() => setModalNavbar(false)}
              />
              <h2 className="mt-4">Menu</h2>
              <ul className="list-unstyled menu-item">
                <li>
                  <ResLink to={propsLink.Business}>
                    {props.NavbarLi.Nav3}
                  </ResLink>
                </li>
                <li>
                  {isBusiness ? (
                    <ResLinkHref
                      href="https://wa.me/628111390993"
                      target="_blank"
                    >
                      {props.NavbarLi.Nav5}
                    </ResLinkHref>
                  ) : (
                    <ResLink to={propsLink.ContactUs}>
                      {props.NavbarLi.Nav4}
                    </ResLink>
                  )}
                </li>
                <li>
                  <ResLink fsize="24px" to={propsLink.RegionalSingapore}>
                    {propsLink.Label.RegionalSingapore}
                  </ResLink>
                </li>
                <li>
                  <ResLink fsize="24px" to={propsLink.RegionalIndonesia}>
                    {propsLink.Label.RegionalIndonesia}
                  </ResLink>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <></>
        )}

        <Navbar className="py-2 px-0" expand="lg">
          <Navbar.Brand>
            {disableNav ? (
              <>
                <img
                  src={navbarTop ? logoPutih : logoWarna}
                  alt="Transfez"
                  style={navbarTop ? { width: "184px" } : { width: "184px" }}
                />
              </>
            ) : (
              <>
                <Link to={propsLink.MainPage}>
                  <img
                    src={navbarTop ? logoPutih : logoWarna}
                    alt="Transfez"
                    style={navbarTop ? { width: "184px" } : { width: "184px" }}
                  />
                </Link>
              </>
            )}
          </Navbar.Brand>
          {/* ----------- */}
          {disableNav ? null : (
            <>
              {!noToggleBusiness && (
                <ToggleBusinessDiv>
                  <ToggleBusiness status={navbarTop} />
                </ToggleBusinessDiv>
              )}

              {/* ----------- */}
              <Nav as="ul" className="ml-auto">
                <Navbar.Collapse id="navbarResponsive">
                  {isBusiness ? null : <NavbarLink data={navbarItems[3]} />}
                  {/* {isBusiness && <NavbarLinkHref data={navbarItems[4]} />} */}
                  <ToggleLanguage props={navbarTop} lang={lang} />
                  {noRegional ? null : (
                    <RegionalTooltip
                      lang={lang}
                      componentPass={
                        <Nav.Item as="li">
                          <StyledSpan to="/">
                            REGIONAL{" "}
                            <img
                              src={isSg ? sgFlag : idFlag}
                              style={{
                                marginLeft: "2px",
                                marginTop: "-4px",
                                marginRight: "4px",
                                filter:
                                  "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25))",
                              }}
                              alt="flag"
                            />
                            <FontAwesomeIcon icon={faAngleDown} size="1x" />
                          </StyledSpan>
                        </Nav.Item>
                      }
                    />
                  )}
                </Navbar.Collapse>
                <ToggleLanguage props={navbarTop} lang={lang} isResponsive />
              </Nav>
              <Navbar.Toggle
                aria-controls="navbarResponsive"
                style={{ border: "none" }}
                disabled
              >
                <FontAwesomeIcon
                  icon={faBars}
                  cursor="pointer"
                  style={navbarTop ? { color: "white" } : { color: "#49a9b5" }}
                  onClick={() =>
                    modalNavbar ? setModalNavbar(false) : setModalNavbar(true)
                  }
                />
              </Navbar.Toggle>
            </>
          )}
          {isBusiness && <ToggleSignInBusiness data={navbarItems[4]} />}
        </Navbar>
      </Container>
    </div>
  )
}

export default NavigationBar
