export function Navigate(event, location) {
  // const path = location.pathname
  if (event === "ID") {
    if (location.pathname.includes('/en')){
      var idLoc = location.pathname.replace('/en' || '/en/','');
      return idLoc
    }
  } else if (event === "EN") {
    if (!location.pathname.includes('/en')){
      var en = "/en"
      var enLoc = en.concat('',location.pathname);
      return enLoc
    }
  }
}
