import styled from "styled-components"

export const ToggleBusinessDiv = styled.div`
  position: relative;
  margin-bottom: 25px;
  margin-left: 100px;
  @media (max-width: 768px) {
    position: absolute;
    visibility: hidden;
  }
`
