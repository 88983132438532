import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { CustomUnderline } from '../Reusable/dot-line'

export const TestingTooltip = ({ children }) => {
  const HtmlTooltip = withStyles(theme => ({
    arrow: {
      color: "white",
      fontSize: "20px",
      marginBottom: "-10px",
      width: '100px',
    },
    tooltip: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      padding: "25px",
      left: '12%',
      minWidth: "400px",
      fontSize: theme.typography.pxToRem(12),
      fontFamily: "GothamBook",
      border: "1px solid white",
      borderRadius: "20px",
      boxShadow: "0px 2.5px 10px rgba(0, 0, 0, 0.15)",
    },
  }))(Tooltip)

  return (
    <>
      <HtmlTooltip
        title={
          <>
            <TooltipH1>Regional</TooltipH1>
            <TooltipH5>Lihat layanan Transfez di tiap regional.</TooltipH5>
            <CustomUnderline mt='1rem' mb='2rem' />
            <Link to="/">
              <TooltipLink>
                Indonesia
                <ArrowIcon />
              </TooltipLink>
            </Link>
            <Link to="/">
              <TooltipLink>
                Singapore
                <ArrowIcon />
              </TooltipLink>
            </Link>
          </>
        }
        interactive
        arrow
        TransitionProps={{ timeout: 350 }}
      >
        {children}
      </HtmlTooltip>
    </>
  )
}

const TooltipH1 = styled.h3`
  color: #cacaca;
  font-size: 24px;
  font-family: "GothamBold";
`

const TooltipH5 = styled.h5`
  color: #828282;
  font-size: 16px;
  font-family: "GothamBook";
  margin-bottom: 10px;
`

const TooltipLink = styled.h5`
  color: #166f7b;
  font-size: 16px;
  font-family: "GothamMedium";
`

const TooltipYellow = styled.div`
  width: 40px;
  height: 5px;
  background-color: #ffd27c;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ArrowIcon = styled(ArrowForwardIosIcon)`
  color: white;
  margin-left: 10px;
  font-size: 16px !important;
  ${TooltipLink}:hover & {
    color: #166f7b;
  }
`
