import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"

export const ToggleSignInBusiness = ({ data }) => {
  const newData = data[0] || {}
  const title = newData?.title
  const whatsappNumber = newData?.to
  const SignInButton = styled(Button)`
    width: 128px;
    height: 28px;
    background-color: #ffd27c;
    color: #166f7b;
    font-family: "GothamMedium";
    font-size: 14px;
    outline: none;
    border-color: #ffd27c;
    border-radius: 25px;
    -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
    box-shadow: -1px 7px 5px -4px #a0a0a0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    :hover,
    :visited,
    :active,
    :focus {
      background-color: #166f7b !important;
      text-decoration: none !important;
      color: #ffd27c !important;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
    @media (max-width: 768px) {
      width: 98px;
      font-size: 10px;
    }
  `

  const Astyled = styled.a`
    :hover {
      text-decoration: none;
    }
    @media (max-width: 992px) {
      display: none;
    }
  `
  return (
    <Astyled href={whatsappNumber} target="_blank">
      <SignInButton>{title}</SignInButton>
    </Astyled>
  )
}

export const ToggleBusiness = ({ status }) => {
  const [isBusiness, setIsBusiness] = useState(false)
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const TextBusiness = isEnglish ? "BUSINESS" : "BISNIS"
  const incBusiness = location.pathname.includes("/business")

  const handleLink = e => {
    e.preventDefault()
    if (isBusiness) {
      if (isEnglish) {
        navigate("/en/business/")
        setIsBusiness(false)
      } else {
        navigate("/business/")
        setIsBusiness(false)
      }
    }
    if (!isBusiness) {
      if (isEnglish) {
        navigate("/en/")
        setIsBusiness(true)
      } else {
        navigate("/")
        setIsBusiness(true)
      }
    }
  }

  useEffect(() => {
    if (incBusiness) {
      setIsBusiness(false)
    } else {
      setIsBusiness(true)
    }
  }, [])

  return (
    <>
      {isBusiness ? (
        <>
          {/* BUSINESS MATI */}
          <div
            style={{
              backgroundColor: "#F3F3F3",
              // backgroundColor: status ? "white" : "#48a9b5",
              filter: "brightness(75%)",
              borderRadius: "25px",
              display: "inline-flex",
              width: 128,
              marginLeft: "80px",
              cursor: "pointer",
              position: "absolute",
              zIndex: 0,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "GothamMedium",
                marginBottom: 0,
                color: "#48a9b5",
                // color: status ? "#48a9b5" : "white",
                paddingBottom: "3px",
                paddingTop: "3px",
                fontSize: 14,
              }}
              onClick={handleLink}
            >
              {TextBusiness}
            </p>
          </div>
          {/* PERSONAL NYALA */}
          <div
            style={{
              backgroundColor: "#FFF",
              // backgroundColor: status ? "white" : "#48a9b5",
              boxShadow: "0px 2.5px 10px rgba(0, 0, 0, 0.15)",
              borderRadius: "25px",
              display: "inline-flex",
              width: 128,
              marginLeft: "-28px",
              textAlign: "center",
              cursor: "pointer",
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "GothamMedium",
                marginBottom: 0,
                color: "#48a9b5",
                // color: status ? "#48a9b5" : "white",
                // padding: "3px 15px 3px 30px",
                paddingBottom: "3px",
                paddingTop: "3px",
                fontSize: 14,
              }}
              onClick={handleLink}
            >
              PERSONAL
            </p>
          </div>{" "}
        </>
      ) : (
        <>
          {/* BUSINESS NYALA */}
          <div
            style={{
              backgroundColor: "#FFF",
              // backgroundColor: status ? "white" : "#48a9b5",
              boxShadow: "0px 2.5px 10px rgba(0, 0, 0, 0.15)",
              borderRadius: "25px",
              display: "inline-flex",
              width: 128,
              textAlign: "center",
              marginLeft: 80,
              cursor: "pointer",
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "GothamMedium",
                marginBottom: 0,
                color: "#48a9b5",
                // color: status ? "#48a9b5" : "white",
                paddingBottom: "3px",
                paddingTop: "3px",
                fontSize: 14,
              }}
              onClick={handleLink}
            >
              {TextBusiness}
            </p>
          </div>
          {/* PERSONAL MATI */}
          <div
            style={{
              backgroundColor: "#F3F3F3",
              // backgroundColor: status ? "white" : "#48a9b5",
              borderRadius: "25px",
              filter: "brightness(75%)",
              display: "inline-flex",
              width: 128,
              marginLeft: "-28px",
              cursor: "pointer",
              position: "absolute",
              zIndex: 0,
              top: 0,
              left: 0,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "GothamMedium",
                marginBottom: 0,
                color: "#48a9b5",
                // color: status ? "#48a9b5" : "white",
                paddingBottom: "3px",
                paddingTop: "3px",
                fontSize: 14,
              }}
              onClick={handleLink}
            >
              PERSONAL
            </p>
          </div>
        </>
      )}

      {/* -------- */}
    </>
  )
}
