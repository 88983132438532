import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { Container, Button } from "react-bootstrap"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import idFlag from "../../images/small-id-flag.svg"
import sgFlag from "../../images/small-sg-flag.svg"

export const RegionalTooltip = ({ componentPass, lang }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(p => !p)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#F6F6F6",
      padding: "5px 10px",
      margin: "-17px 0px 0px 15px",
      fontSize: theme.typography.pxToRem(12),
      transition: "500ms",
    },
  }))(Tooltip)

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <HtmlTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionProps={{ timeout: 600 }}
            interactive
            placement="bottom-start"
            title={
              <>
                <CustomLink to={lang ? "/en" : "/"}>
                  <TooltipLink mt="8px" mb="7px">
                    Indonesia <img src={idFlag} className="ml-2" alt="indonesia-flag"/>
                  </TooltipLink>
                </CustomLink>
                <CustomLink to={lang? "/en/sg" : "/sg"}>
                  <TooltipLink mt="5px" mb="5px">
                    {lang ? "Singapore" : "Singapura"} <CustomImg src={sgFlag} alt="malaysia-flag"/>
                  </TooltipLink>
                </CustomLink>
              </>
            }
          >
            <span onClick={handleToggle}>{componentPass}</span>

            {/* <Button onClick={handleTooltipOpen}>Click</Button> */}
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </>
  )
}

const CustomLink = styled(Link)`
  cursor: pointer;
`

const TooltipLink = styled.h5`
  color: #48a9b5;
  font-size: 16px;
  font-family: "GothamMedium";
  text-decoration: none;
  margin-top: ${p => p.mt};
  margin-bottom: ${p => p.mb};
  display: flex;
  justify-content: space-between;
  :hover {
    color: #166f7b;
    text-decoration: underline;
    text-decoration-color: #166f7b;
  }
`

const TooltipYellow = styled.div`
  width: 40px;
  height: 5px;
  background-color: #ffd27c;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ArrowIcon = styled(ArrowForwardIosIcon)`
  color: white;
  margin-left: 10px;
  font-size: 16px !important;
  ${TooltipLink}:hover & {
    color: #166f7b;
  }
`

const CustomImg = styled.img`
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
`
