import React from "react"
import { navigate } from "gatsby"
import { Navigate } from "../navigation"
import { Nav, Form } from "react-bootstrap"
import { useLocation } from "@reach/router"
import toggleImg from "../../images/toggle-language.svg"
import toggleImgWhite from "../../images/toggle-language-white.svg"
import styled from "styled-components"

function ToggleLanguage({ props, lang, isResponsive }) {
  const location = useLocation()
  const handleChange = event => {
    const kemana = Navigate(event, location)
    navigate(kemana)
  }

  return (
    <>
      <CustomDiv min992={isResponsive && "none"} mright={isResponsive && "10px"}>
        <Nav.Item as="li" style={{ fontFamily: "GothamMedium" }}>
          <CustomFormControl
            size="md"
            as="select"
            onChange={e => handleChange(e.target.value)}
            bimage={props ? `url(${toggleImgWhite})` : `url(${toggleImg})`}
            // style={
            //   props
            //     ? {
            //         color: "white",
            //       }
            //     : {
            //         color: "#49a9b5",
            //       }
            // }
          >
            {lang ? (
              <>
                <option value="EN" selected style={{color:'#48a9b5'}}>
                  EN
                </option>
                <option value="ID" style={{color:'#48a9b5'}}>ID</option>
              </>
            ) : (
              <>
                <option value="EN" style={{color:'#48a9b5'}}>EN</option>
                <option value="ID" selected style={{color:'#48a9b5'}}>
                  ID
                </option>
              </>
            )}
          </CustomFormControl>
        </Nav.Item>
      </CustomDiv>
    </>
  )
}

export default ToggleLanguage

const CustomDiv = styled.div`
  margin-right: ${p => p.mright};
  @media (min-width: 992px) {
    display: ${p => p.min992};
  }
`

const CustomFormControl = styled(Form.Control)`
  background-color: transparent;
  border: none;
  height: auto;
  padding: 7px 6px 6px 6px;
  font-size: 14px;
  -webkit-appearance: none;
  color: transparent;
  cursor: pointer;
  :hover,
  :focus,
  :active {
    border: none;
    outline: none;
  }
  background-image: ${p => p.bimage};
  -webkit-padding-start: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`
